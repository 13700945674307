import React from "react"
import styled from "styled-components"
import { MariaSecondGradientColor, MariaSecondary, MariaText} from "../utils/colors"

const PostCard = ({children}) => {
  return (
    <Card>
    {children}
  </Card>
  )

}


const Card = styled.div`
  color: ${MariaSecondary};
  display: inline-grid;
  margin: 0.4rem 0.1rem 0.4rem 0.1rem;
  height: 17rem;
  width: 17rem;
  position: relative;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover {
    z-index: 2;
    box-shadow:
    0 0 25px #fff,
    -10px 0 80px ${MariaSecondGradientColor},
    10px 0 80px ${MariaText};
  }
`

export default PostCard