import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {MariaPrimary, MariaSecondary} from "../utils/colors"

const PortfolioNavigationMenu = () => {
    return (
        <NavWrapper>
          <Navigation>
            <NavUl>
              <NavLi>
                <NavLink to="/portfolio/">
                  ALL
                </NavLink>
              </NavLi>
              <NavLi>
                <NavLink to="/videos/">
                  VIDEOS
                </NavLink>
              </NavLi>
              <NavLi>
                <NavLink to="/podcasts/">
                  PODCASTS
                </NavLink>
              </NavLi>
              <NavLi>
                <NavLink to="/coding/">
                  CODING
                </NavLink>
              </NavLi>
            </NavUl>
          </Navigation>
          </NavWrapper>
    );
  };

const NavWrapper = styled.div`
    margin: 0 0 1rem 0;
    padding: 0;
    `

const Navigation = styled(props => <nav {...props} />)`
    text-align: center;
    padding: 0;
`
const NavUl = styled(props => <ul {...props} />)`
    margin: 0;
`
const NavLi = styled(props => <li {...props} />)`
    display: inline-block;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-bottom: 0.2rem;
    font-size: 0.9rem;
    font-weight: bolder;
    margin-bottom: 0;
    `

const NavLink = styled(props => <Link {...props} />)`
    z-index: 3;
    color: ${MariaPrimary};
    background: ${MariaSecondary};
    font-family: "Press Start 2P";
    padding: 0.1rem 0.5rem;
    font-size: 0.45rem;
    border-radius: 5px;
    position: relative;
    text-decoration: none;
    display: inline-block;

    &:hover {
      transform: scaleX(1);
    }

    `

  
    export default PortfolioNavigationMenu